import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";
import routes from "@/routes/routes";

export default function RouteProvider(): React.ReactElement {
  const router = createBrowserRouter(routes);

  return (
    <Suspense
      fallback={
        <LoadingOverlay
          visible
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}
