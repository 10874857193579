/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import type { CustomRoute } from "@/types";
import ClientsIcon from "./icons/clients-icon";

const Container = lazy(() => import("@/components/container"));
const Clients = lazy(() => import("./views/clients"));
const ClientDetails = lazy(() => import("./views/client-details"));

const routes: CustomRoute = {
  id: "clients",
  title: "All clients",
  path: "clients",
  element: <Container of={<Clients />} childPaths="/clients/:clientId" />,
  Icon: ClientsIcon,
  children: [
    {
      id: "client-details",
      title: "Client Details",
      path: ":clientId",
      element: <ClientDetails />,
    },
  ],
};

export default routes;
