// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/code-highlight/styles.css';
// ...
import {
  Button,
  MantineProvider as MProvider,
  Select,
  createTheme,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { colors } from "@/config/theme";

// required by components like DateInput to work properly
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import ChevronDownIcon from "@/icons/chevron-down-icon";

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  children: React.ReactElement;
}

const theme = createTheme({
  /** Put your mantine theme override here */
  primaryColor: "blue",
  primaryShade: { light: 7, dark: 8 },
  defaultRadius: "md",
  colors: {
    gray: colors.gray,
    blue: colors.blue,
    red: colors.red,
    yellow: colors.yellow,
    green: colors.green,
  },

  components: {
    Select: Select.extend({
      defaultProps: { rightSection: <ChevronDownIcon /> },
    }),

    Button: Button.extend({
      classNames(_theme, _props, _ctx) {
        return {
          root: "data-[disabled=true]:cursor-not-allowed",
        };
      },
    }),
  },
});

export default function MantineProvider(props: Props): React.ReactElement {
  const { children } = props;

  return (
    <MProvider theme={theme}>
      <Notifications position="top-right" />
      {children}
    </MProvider>
  );
}
