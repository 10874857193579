/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import type { CustomRoute } from "@/types";
import UsersIcon from "./icons/users-icon";

const AccessControl = lazy(() => import("./views/access-control"));

const routes: CustomRoute = {
  id: "access-control",
  title: "Access control",
  path: "access-control",
  element: <AccessControl />,
  Icon: UsersIcon,
  children: [],
};

export default routes;
