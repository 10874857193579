export default function registerSW(config: {
  onSuccess?: () => void;
  onError?: (msg: string) => void;
}): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );

        config.onSuccess?.();
      })
      .catch((err) => {
        console.error("Service Worker registration failed:", err);

        config.onError?.("You might not receive notifications");
      });
  } else {
    console.log("Service Worker not supported.");

    config.onError?.("Service Worker is not supported by your browser");
  }
}
