import { type ColorVariant } from "@/config/theme";
import clsx from "clsx";

export default function getClassNames(
  variant: ColorVariant,
  hoverEffect: boolean,
): {
  label: string;
  root: string;
} {
  if (variant === "gray") {
    return {
      label: "text-gray-700",
      root: clsx("bg-gray-50", hoverEffect && "hover:bg-gray-100"),
    };
  }
  if (variant === "blue") {
    return {
      label: "text-blue-700",
      root: clsx("bg-blue-50", hoverEffect && "hover:bg-blue-100"),
    };
  }
  if (variant === "red") {
    return {
      label: "text-red-700",
      root: clsx("bg-red-50", hoverEffect && "hover:bg-red-100"),
    };
  }
  if (variant === "yellow") {
    return {
      label: "text-yellow-700",
      root: clsx("bg-yellow-50", "hover:bg-yellow-100"),
    };
  }
  if (variant === "green") {
    return {
      label: "text-green-700",
      root: clsx("bg-green-50", hoverEffect && "hover:bg-green-100"),
    };
  }
  if (variant === "bluegray") {
    return {
      label: "text-bluegray-700",
      root: clsx("bg-bluegray-50", hoverEffect && "hover:bg-bluegray-100"),
    };
  }
  if (variant === "bluelight") {
    return {
      label: "text-bluelight-700",
      root: clsx("bg-bluelight-50", hoverEffect && "hover:bg-bluelight-100"),
    };
  }
  if (variant === "indigo") {
    return {
      label: "text-indigo-700",
      root: clsx("bg-indigo-50", hoverEffect && "hover:bg-indigo-100"),
    };
  }
  if (variant === "purple") {
    return {
      label: "text-purple-700",
      root: clsx("bg-purple-50", hoverEffect && "hover:bg-purple-100"),
    };
  }
  if (variant === "pink") {
    return {
      label: "text-pink-700",
      root: clsx("bg-pink-50", hoverEffect && "hover:bg-pink-100"),
    };
  }
  if (variant === "rose") {
    return {
      label: "text-rose-700",
      root: clsx("bg-rose-50", hoverEffect && "hover:bg-rose-100"),
    };
  }
  if (variant === "orange") {
    return {
      label: "text-orange-700",
      root: clsx("bg-orange-50", hoverEffect && "hover:bg-orange-100"),
    };
  }

  return { label: "", root: "" };
}
