/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import type { CustomRoute } from "@/types";
import ChatIcon from "./icons/chat-icon";

const Chat = lazy(() => import("./views/chat"));

const routes: CustomRoute = {
  id: "chat",
  title: "Chat",
  path: "chat",
  element: <Chat />,
  Icon: ChatIcon,
  children: [],
};

export default routes;
