/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import { routes as authRoutes } from "@/features/auth";
import { routes as clientsRoutes } from "@/features/clients";
import { routes as taxRoutes } from "@/features/tax-inquiries";
import { routes as chatRoutes } from "@/features/chat";
import { routes as accessRoutes } from "@/features/access-control";
import { routes as settingsRoutes } from "@/features/settings";
import type { CustomRoute } from "@/types";
import PDFViewer from "@/views/pdf-viewer";

const Root = lazy(() => import("@/views/root"));
const NotFound = lazy(() => import("@/views/not-found"));
const Error = lazy(() => import("@/views/error"));
const InviteUser = lazy(() => import("@/views/invite-user"));

const routes: CustomRoute[] = [
  {
    id: "root",
    title: "TopFin Web",
    path: "/",
    element: <Root getRoutes={() => routes} />,
    loader: async () => null,
    errorElement: <Error />,
    children: [
      clientsRoutes,
      taxRoutes,
      chatRoutes,
      accessRoutes,
      settingsRoutes,
      {
        id: "pdf-viewer",
        title: "PDF Viewer",
        path: "pdf-viewer",
        element: <PDFViewer />,
      },
    ],
  },
  {
    id: "invite-user",
    title: "Invite user",
    path: "invite",
    element: <InviteUser />,
  },
  authRoutes,
  {
    id: "not-found",
    title: "not-found",
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
