import type { CustomRoute } from "@/types";
import SettingsIcon from "./components/settings-icon";

const routes: CustomRoute = {
  id: "settings",
  title: "Settings",
  path: "settings",
  element: <div>Settings</div>,
  Icon: SettingsIcon,
  children: [],
};

export default routes;
