import { languages } from "@/locales";

const settings = {
  baseURL: import.meta.env.VITE_BASE_URL,
  staleTime: 1000 * 60 * 2, // 2 mins,
  cacheTime: 1000 * 60 * 60 * 24, // 24 hours
  requestTimeout: 1000 * 60 * 3, // 3 mins
  idleTimeout: 5000, // 5 sec
  toastDuration: 3, // 3 sec
  rowsPerPage: 10,
  defaultLanguage: languages.en,
} as const;

export default settings;
