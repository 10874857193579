const colors = {
  gray: [
    "#fcfcfd",
    "#f9fafb",
    "#f2f4f7",
    "#eaecf0",
    "#d0d5dd",
    "#98a2b3",
    "#667085",
    "#475467",
    "#344054",
    "#1d2939",
    "#101828",
  ],
  blue: [
    "#f5faff",
    "#eff8ff",
    "#d1e9ff",
    "#b2ddff",
    "#84caff",
    "#53b1fd",
    "#2e90fa",
    "#1570ef",
    "#175cd3",
    "#1849a9",
    "#194185",
  ],
  red: [
    "#fffbfa",
    "#fef3f2",
    "#fee4e2",
    "#fecdca",
    "#fda29b",
    "#f97066",
    "#f04438",
    "#d92d20",
    "#b42318",
    "#912018",
    "#7a271a",
  ],
  yellow: [
    "#fffcf5",
    "#fffaeb",
    "#fef0c7",
    "#fedf89",
    "#fec84b",
    "#fdb022",
    "#f79009",
    "#dc6803",
    "#b54708",
    "#93370d",
    "#7a2e0e",
  ],
  green: [
    "#f6fef9",
    "#ecfdf3",
    "#d1fadf",
    "#a6f4c5",
    "#6ce9a6",
    "#32d583",
    "#12b76a",
    "#039855",
    "#027a48",
    "#05603a",
    "#054f31",
  ],
  bluegray: [
    "#fcfcfd",
    "#f8f9fc",
    "#eaecf5",
    "#d5d9eb",
    "#afb5d9",
    "#717bbc",
    "#4e5ba6",
    "#3e4784",
    "#363f72",
    "#293056",
    "#101323",
  ],
  bluelight: [
    "#f5fbff",
    "#f0f9ff",
    "#e0f2fe",
    "#b9e6fe",
    "#7cd4fd",
    "#36bffa",
    "#0ba5ec",
    "#0086c9",
    "#026aa2",
    "#065986",
    "#0b4a6f",
  ],
  indigo: [
    "#f5f8ff",
    "#eef4ff",
    "#e0eaff",
    "#c7d7fe",
    "#a4bcfd",
    "#8098f9",
    "#6172f3",
    "#444ce7",
    "#3538cd",
    "#2d31a6",
    "#2d3282",
  ],
  purple: [
    "#fafaff",
    "#f4f3ff",
    "#ebe9fe",
    "#d9d6fe",
    "#bdb4fe",
    "#9b8afb",
    "#7a5af8",
    "#6938ef",
    "#5925dc",
    "#4a1fb8",
    "#3e1c96",
  ],
  pink: [
    "#fef6fb",
    "#fdf2fa",
    "#fce7f6",
    "#fcceee",
    "#faa7e0",
    "#f670c7",
    "#ee46bc",
    "#dd2590",
    "#c11574",
    "#9e165f",
    "#851651",
  ],
  rose: [
    "#fff5f6",
    "#fff1f3",
    "#ffe4e8",
    "#fecdd6",
    "#fea3b4",
    "#fd6f8e",
    "#f63d68",
    "#e31b54",
    "#c01048",
    "#a11043",
    "#89123e",
  ],
  orange: [
    "#fffaf5",
    "#fff6ed",
    "#ffead5",
    "#fddcab",
    "#feb273",
    "#fd853a",
    "#fb6514",
    "#ec4a0a",
    "#c4320a",
    "#9c2a10",
    "#7e2410",
  ],
} as const;

export type ColorVariant = keyof typeof colors;

const sizes = {};

export { colors, sizes };
