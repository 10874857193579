import { notifications } from "@mantine/notifications";
import registerSW from "@/helpers/register-sw";

interface Props {
  children: React.ReactElement;
}

registerSW({
  onError: (message) => {
    notifications.show({
      title: "Failed to register service worker",
      message,
      color: "red",
    });
  },
});

export default function SWRegistrationProvider(
  props: Props,
): React.ReactElement {
  const { children } = props;

  return children;
}
