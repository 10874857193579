/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import type { CustomRoute } from "@/types";
import FileIcon from "./icons/file-icon";

const TaxInquiries = lazy(() => import("./views/tax-inquiries"));

const routes: CustomRoute = {
  id: "tax-inquiries",
  title: "Tax inquiries",
  path: "tax-inquiries",
  element: <TaxInquiries />,
  Icon: FileIcon,
  children: [],
};

export default routes;
