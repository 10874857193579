import type { AppLang } from "@/types";
import en from "./en.json";
import es from "./es.json";
import ru from "./ru.json";

export default { en, es, ru };

export const languages: { [T in AppLang]: T } = {
  en: "en",
  es: "es",
  ru: "ru",
};
