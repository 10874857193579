import request from "@/utils/axios";
import type { AxiosResponse } from "axios";
import type { InvitationResponse, LoginResponse } from "../types";

export async function login(data: {
  email: string;
  password: string;
  firebase_token: string;
}): Promise<LoginResponse> {
  const res: LoginResponse = await request({
    url: "/api/v1/auth-accountant/login",
    method: "post",
    data,
  });

  return res;
}

export async function acceptInvitation(data: {
  first_name: string;
  last_name: string;
  password: string;
  invitation_id: string;
}): Promise<InvitationResponse> {
  const res: InvitationResponse = await request({
    url: "/api/v1/auth-accountant/accept-invitation",
    method: "post",
    data,
  });

  return res;
}

export async function refreshToken(data: {
  refresh: string;
}): Promise<AxiosResponse<LoginResponse>> {
  const res = await request({
    url: "",
    method: "post",
    data,
  });

  return res;
}
