import { forwardRef } from "react";
import { Badge } from "@mantine/core";
import clsx from "clsx";
import { type ColorVariant } from "@/config/theme";
import getClassNames from "../helpers/get-class-names";

interface Props {
  name: React.ReactNode;
  variant: ColorVariant;
  className?: string;
  hoverEffect?: boolean;
}

function StBadge(
  props: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const { name, variant, className, hoverEffect } = props;

  const classNames = getClassNames(variant, hoverEffect ?? false);

  return (
    <Badge
      ref={ref}
      {...{ ...props, hoverEffect: undefined }}
      tt="capitalize"
      className={clsx(
        "rounded-2xl px-2 py-[2px] text-xs font-medium",
        className,
      )}
      classNames={{
        label: clsx("", classNames.label), // doesn't work: `text-${variant}-700`
        root: clsx("", classNames.root),
      }}
    >
      {name}
    </Badge>
  );
}

const StatusBadge = forwardRef<HTMLDivElement, Props>(StBadge);

export default StatusBadge;

StBadge.defaultProps = {
  className: "",
  hoverEffect: false,
};
