import {
  QueryProvider,
  AuthProvider,
  RouteProvider as Routes,
  LangProvider,
  PrototypeExtensionsProvider,
  MantineProvider,
  SWRegistrationProvider,
} from "./providers";

export default function App(): React.ReactElement {
  return (
    <MantineProvider>
      <LangProvider>
        <QueryProvider>
          <AuthProvider>
            <PrototypeExtensionsProvider>
              <SWRegistrationProvider>
                <Routes />
              </SWRegistrationProvider>
            </PrototypeExtensionsProvider>
          </AuthProvider>
        </QueryProvider>
      </LangProvider>
    </MantineProvider>
  );
}
